"use client";

import {
  createContext,
  type PropsWithChildren,
  useContext,
  type FC,
} from "react";
import { type NavData } from "@/lib/experience/util";

export const NavigationDataContext = createContext<NavData>({
  footer: [],
  header: [],
});

export const NavigationDataProvider: FC<
  PropsWithChildren<{ navigationDataContext: NavData }>
> = ({ children, navigationDataContext }) => {
  return (
    <NavigationDataContext.Provider value={navigationDataContext}>
      {children}
    </NavigationDataContext.Provider>
  );
};

export const useNavigationDataContext = () => useContext(NavigationDataContext);
