import { type FC, type PropsWithChildren } from "react";

const sortObj = <T extends Record<string, any>>(obj: T): T => {
  const sorted: Record<string, any> = {};

  for (const key of Object.keys(obj).sort()) {
    const value = obj[key];
    if (typeof value === "object" && !Array.isArray(value)) {
      sorted[key] = sortObj(value);
    } else {
      sorted[key] = value;
    }
  }

  return sorted as T;
};

export const Body: FC<PropsWithChildren> = ({ children }) => (
  <div className="h-screen overflow-y-scroll border border-solid border-black bg-white text-black">
    {children}
  </div>
);

export const Button: FC<PropsWithChildren> = ({ children }) => {
  return (
    <button className="m-2 rounded border border-solid border-[#400090] bg-white px-2 py-1 font-semibold capitalize text-[#6633a6]">
      {children}
    </button>
  );
};

export const Json: FC<{ data: Record<string, any> }> = ({ data }) => (
  <pre>{JSON.stringify(sortObj(data), null, 2)}</pre>
);
