"use client";
import { useEffect } from "react";
import { setTimeout } from "timers";

export interface AffilimatePageProps {
  apiToken: string;
}

export default function Affilimate({ apiToken }: AffilimatePageProps) {
  const initializeAffilimate = async (apiToken: string) => {
    await new Promise((resolve) => setTimeout(resolve, 2000));

    // Dynamically import the library
    const Affilimate = await require("@affilimate/snippet");

    const config = {
      allowErrorReporting: true,
    };

    // async call since it returns a promise
    Affilimate.init(apiToken, config);
  };

  useEffect(() => {
    (async () => {
      await initializeAffilimate(apiToken);
    })();
  }, [apiToken]);

  return <></>;
}
