"use client";

import { HttpLink } from "@apollo/client";
import {
  ApolloNextAppProvider,
  ApolloClient,
  InMemoryCache,
} from "@apollo/experimental-nextjs-app-support";

if (!process.env.NEXT_PUBLIC_CONTENT_GATEWAY_ENDPOINT) {
  throw new Error("NEXT_PUBLIC_CONTENT_GATEWAY_ENDPOINT env var is not set");
}

// have a function to create a client for you
function makeClient({ config }: { config: Record<string, unknown> }) {
  return () => {
    const { headers } = config;
    const httpLink = new HttpLink({
      // you can disable result caching here if you want to
      // (this does not work if you are rendering your page with `export const dynamic = "force-static"`)
      fetchOptions: { cache: "no-store" },
      headers: {
        ...(headers || {}),
      },
      // this needs to be an absolute url, as relative urls cannot be used in SSR
      uri: process.env.NEXT_PUBLIC_CONTENT_GATEWAY_ENDPOINT,
      // you can override the default `fetchOptions` on a per query basis
      // via the `context` property on the options passed as a second argument
      // to an Apollo Client data fetching hook, e.g.:
      // const { data } = useSuspenseQuery(MY_QUERY, { context: { fetchOptions: { cache: "force-cache" }}});
    });

    return new ApolloClient({
      cache: new InMemoryCache(),
      link: httpLink,
    });
  };
}

// you need to create a component to wrap your app in
export function ApolloWrapper({
  children,
  config,
}: React.PropsWithChildren & { config: any }) {
  return (
    <ApolloNextAppProvider makeClient={makeClient({ config })}>
      {children}
    </ApolloNextAppProvider>
  );
}
