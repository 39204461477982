import(/* webpackMode: "eager", webpackExports: ["__esModule","default"] */ "/app/apps/content/node_modules/next/dist/client/app-dir/link.js");
;
import(/* webpackMode: "eager" */ "/app/apps/content/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/app/apps/content/src/app/globals.css");
;
import(/* webpackMode: "eager" */ "/app/apps/content/node_modules/next/font/local/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"\",\"arguments\":[{\"src\":\"../font/Sans/YahooProductSansVF_normal_normal.woff2\",\"variable\":\"--font-yahoo-sans\",\"weight\":\"200 900\"}],\"variableName\":\"yahooSans\"}");
;
import(/* webpackMode: "eager" */ "/app/apps/content/node_modules/next/font/local/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"\",\"arguments\":[{\"src\":[{\"path\":\"../font/Centra/CentraNo2Book_normal_normal.woff2\",\"style\":\"normal\",\"weight\":\"400\"},{\"path\":\"../font/Centra/CentraNo2Bold_normal_normal.woff2\",\"style\":\"normal\",\"weight\":\"700\"},{\"path\":\"../font/Centra/CentraNo2ExtraBold_normal_normal.woff2\",\"style\":\"normal\",\"weight\":\"800\"}],\"variable\":\"--font-centra\"}],\"variableName\":\"centra\"}");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/apps/content/src/components/affilimate/index.tsx");
;
import(/* webpackMode: "eager" */ "/app/apps/content/src/components/common/Perf/DebugBar.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ConfigProvider"] */ "/app/apps/content/src/contexts/ConfigContext/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["NavigationDataProvider"] */ "/app/apps/content/src/contexts/NavigationDataContext/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["OtelBrowserInit"] */ "/app/apps/content/src/lib/otel/components/Browser.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["RequestProvider"] */ "/app/apps/content/src/lib/request/client.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ApolloWrapper"] */ "/app/packages/api/contentGateway/components/ApolloWrapper.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["EsiCanary"] */ "/app/packages/edge/components/EsiCanary.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["useEsiVars"] */ "/app/packages/edge/hooks/useEsiVars.ts");
;
import(/* webpackMode: "eager", webpackExports: ["Comscore"] */ "/app/packages/i13n/components/Comscore.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Rapid","addContentProgression","endContentProgression","beaconClick","beaconLinkViews","beaconPageview","reInit"] */ "/app/packages/i13n/components/Rapid.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["useModulePosition","ModulePositionProvider"] */ "/app/packages/i13n/hooks/useModulePosition.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["useRapid"] */ "/app/packages/i13n/hooks/useRapid.ts");
;
import(/* webpackMode: "eager", webpackExports: ["IntlProvider"] */ "/app/packages/i18n/lib/shim.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["useDeviceSize"] */ "/app/packages/util/hooks/useDeviceSize.ts");
;
import(/* webpackMode: "eager", webpackExports: ["useIntersectionObserver","useInView","useStickyActive","useObservedRef"] */ "/app/packages/util/hooks/useIntersectionObserver.ts");
;
import(/* webpackMode: "eager", webpackExports: ["useMatchesMediaQuery"] */ "/app/packages/util/hooks/useMatchesMediaQuery.ts");
;
import(/* webpackMode: "eager", webpackExports: ["detectBot"] */ "/app/packages/util/lib/client.ts");
