"use client";

import { type Entry } from "@yahoo-news/perf";
import { type ReadonlyHeaders } from "next/dist/server/web/spec-extension/adapters/headers";
import { type FC } from "react";
import { useYnet } from "@/hooks/useYnet";
import { useRequestContext } from "@/lib/request/client";
import { HAR } from "./Har";
import { Body, Button, Json } from "./Item";
import { TabList, TabItem } from "./TabLayout";

type Props = {
  config: any;
  context: Record<string, any>;
  entries: Entry[];
  headers: ReadonlyHeaders;
};

export const DebugBar: FC<Props> = ({ config, context, entries, headers }) => {
  const ynet = useYnet();
  const requestContext = useRequestContext();

  if (!ynet && process.env.NODE_ENV !== "development") {
    return;
  }

  return (
    <div className="z-30 w-full bg-dirty-seagull">
      <TabList>
        <TabItem label={<Button>Server Context</Button>}>
          <Body>
            <Json data={{ context }} />
          </Body>
        </TabItem>
        <TabItem label={<Button>Client Context</Button>}>
          <Body>
            <Json data={{ requestContext }} />
          </Body>
        </TabItem>
        <TabItem label={<Button>Headers</Button>}>
          <Body>
            <Json data={Object.fromEntries(headers)} />
          </Body>
        </TabItem>
        <TabItem label={<Button>Config</Button>}>
          <Body>
            <Json data={{ config }} />
          </Body>
        </TabItem>
        <TabItem label={<Button>Network</Button>}>
          <Body>
            <HAR entries={entries} />
          </Body>
        </TabItem>
      </TabList>
    </div>
  );
};
