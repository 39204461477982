"use client";

import { createContext, useContext, type FC, type ReactNode } from "react";
import { type Config } from "@/configs";

const ConfigContext = createContext<Config>({} as Config);

interface Props {
  children: ReactNode;
  config: Config;
}

export const ConfigProvider: FC<Props> = ({ children, config }) => {
  return (
    <ConfigContext.Provider value={config}>{children}</ConfigContext.Provider>
  );
};

export const useConfigContext = () => useContext(ConfigContext);
