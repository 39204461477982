import { type Entry } from "@yahoo-news/perf";
import { type FC, useState, lazy, Suspense } from "react";

interface Props {
  entries: Entry[];
}

const NetworkViewer = lazy(() =>
  import("network-viewer").then(({ NetworkViewer }) => ({
    default: NetworkViewer,
  })),
);

export const HAR: FC<Props> = ({ entries }) => {
  const [loaded, setLoaded] = useState(false);

  const placeholder = loaded ? null : (
    <div className="absolute inset-0 flex items-center justify-center">
      Loading...
    </div>
  );

  return (
    <Suspense fallback={placeholder}>
      <NetworkViewer
        containerClassName={loaded ? "" : "hidden"}
        data={{
          log: {
            entries,
            pages: [],
          },
        }}
        onDataLoaded={() => setLoaded(true)}
        options={{
          showImportHAR: false,
        }}
      />
    </Suspense>
  );
};
